<template>
  <section>
    <h2>Relación: OBS - Objeto específico de gasto</h2>

    <!-- Botón para agregar nueva relacion -->
    <v-btn v-if="haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])" solid color="secondary" @click="
      (dialog = true),
      (relacion = {
        id_relacion: null,
        id_obs: null,
        id_catalogo_cifrado: null,
      })
      " class="mt-4">Agregar nueva relación</v-btn>

    <v-row class="mt-4">
      <v-col cols="12" sm="4">
        <v-text-field outlined v-model="busqueda" label="Búsqueda" clearable></v-text-field>
      </v-col>
    </v-row>

    <v-data-table :search="busqueda" :headers="headers" :items="relaciones" class="elevation-0">
      <template v-slot:item.acciones="{ item }"
        v-if="haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])">
        <v-btn icon color="primary" @click="editarRelacion(item), (solicitudes_encontradas = [])">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="
          dialogoConfirmacion = true;
        relacion = item;
        ">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="600px"
      v-if="haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])">
      <v-card>
        <v-card-title>
          <span class="headline">Modificar relación OBS - OEG</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="relacion.id_obs" :items="obsLs" item-text="codigo_nombre" item-value="id"
                  label="OBS" outlined required></v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="relacion.id_relacion == null">
                <v-autocomplete
                  v-model="select_tipo_cifrado"
                  :items="ctl_tipos_cifrados"
                  item-text="nombre"
                  item-value="id"
                  label="Tipo de elemento de cifrado"
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :disabled="select_tipo_cifrado == null"
                  :readonly="relacion.id_relacion !== null"
                  v-model="relacion.id_catalogo_cifrado"
                  :items="oegLs"
                  item-text="codigo_nombre"
                  item-value="id"
                  label="Objeto específico de gasto"
                  outlined
                  required
                />
              </v-col>
            </v-row>

            <div v-if="solicitudes_encontradas.length > 0">
              <h6 class="text-h6 secondary--text">Solicitudes encontradas</h6>

              <div v-for="solicitud in solicitudes_encontradas" class="mt-4">
                <p class="mb-0">Institución: {{ solicitud.institucion }}</p>
                <p class="mb-0">Código: {{ solicitud.codigo_sn }}</p>
                <p class="mb-0">
                  Cifrado: {{ solicitud.cifrado_presupuestario }}
                </p>
                <p class="mb-0">
                  Específico de gasto: {{ solicitud.nombre_oeg }}
                </p>
                <p class="mb-0">Estado: {{ solicitud.estado_sn }}</p>
                <p class="mb-0">
                  Creada:
                  {{
                    moment(solicitud.created_at, "").format(
                      "DD-MM-YYYY hh:mm A"
                    )
                  }}
                </p>
                <v-divider class="mt-2"></v-divider>
              </div>
            </div>

            <v-alert v-if="solicitudes_encontradas.length > 0" type="warning" class="mt-4" outlined icon="mdi-alert">Se
              encontró {{ solicitudes_encontradas.length }} solicitud(es)
              con el mismo código OBS y objeto específico de gasto. Para
              proceder con la actualización debe autorizar la actualización de
              las solicitudes de necesidad al nuevo OBS configurado. Esto
              significa que los usuarios ya no verán el dato con el que crearon
              la solicitud, sino el nuevo configurado.</v-alert>

            <v-row v-if="solicitudes_encontradas.length > 0">
              <v-col cols="12" sm="6" order="2" order-sm="1">
                <v-btn
                  outlined
                  color="warning"
                  @click="guardarRelacion(true)"
                  block
                >
                  Autorizar modificación
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" order="1" order-sm="2">
                <v-btn
                  solid
                  color="secondary"
                  @click="
                    (solicitudes_encontradas = []),
                      (dialog = false),
                      (relacion = {
                        id_relacion: null,
                        id_obs: null,
                        id_catalogo_cifrado: null,
                      })
                  "
                  block
                >
                  No autorizar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6" v-if="solicitudes_encontradas.length == 0">
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click="
            (dialog = false),
            (relacion = {
              id_relacion: null,
              id_obs: null,
              id_catalogo_cifrado: null,
            })
            ">Cancelar</v-btn>
          <v-btn :disabled="relacion.id_obs === null || relacion.id_catalogo_cifrado === null
            " color="secondary" solid min-width="30%" @click="guardarRelacion(false)">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialogComponent :show="dialogoConfirmacion" btnConfirmar="Eliminar" title="¿Deseas eliminar la relación?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles." @close="dialogoConfirmacion = false"
      @confirm="eliminarRelacion" />
  </section>
</template>

<script>
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";

export default {
  name: "Configuracion",
  validations: {},
  components: {
    ConfirmationDialogComponent,
  },
  data: () => ({
    busqueda: "",
    relaciones: [],
    headers: [
      {
        text: "Código OBS",
        sortable: false,
        value: "codigo_obs",
        width: 15,
        align: "right",
      },
      { text: "OBS", sortable: false, value: "nombre_obs", width: 25 },
      {
        text: "Código objeto específico de gasto",
        sortable: false,
        value: "codigo_catalogo_cifrado",
        width: 20,
        align: "right",
      },
      {
        text: "Objeto específico de gasto",
        sortable: false,
        value: "nombre_catalogo_cifrado",
        width: 25,
      },
      { text: "Acciones", sortable: false, value: "acciones", width: 15 },
    ],

    dialog: false,
    relacion: {
      id_relacion: null,
      id_obs: null,
      id_catalogo_cifrado: null,
    },
    obsLs: [],
    oegLs: [],
    dialogoConfirmacion: false,
    solicitudes_encontradas: [],

    ctl_tipos_cifrados: [],
    select_tipo_cifrado: null,
  }),
  computed: {},
  methods: {
    async getRelacionObsOeg() {

      const response = await this.services.Cifrados.getRelacionObsOeg();
      if (response.status === 200) this.relaciones = response.data;

    },
    async getObsList() {

      const response = await this.services.Cifrados.getSegmentos({
        pagination: false,
      });
      if (response.status === 200) {
        this.obsLs = response.data.map((item) => {
          return {
            codigo_nombre: `${item.codigo} - ${item.nombre}`,
            ...item,
          };
        });
      }

    },
    async getOegList(id_elemento) {
      this.showLoader(id_elemento);
      const response = await this.services.Cifrados.getCatalogoCifrados({
        pagination: false,
        id_elemento: id_elemento,
        todos: true,
      });
      if (response.status === 200)
        this.oegLs = response.data.map((item) => {
          return {
            codigo_nombre: `${item.codigo} - ${item.nombre} ${item?.Institucion?.nombre ? ' (' + item?.Institucion?.nombre + ')' : ''}`,
            ...item,
          };
        });

    },
    async guardarRelacion(saltarValidacion = false) {

      const response = await this.services.Cifrados.guardarRelacionObsOeg(
        {
          ...this.relacion,
          saltar_validacion: saltarValidacion,
        }
      );

      if (response.status === 200) {
        if (response.data.solicitudes) {
          this.solicitudes_encontradas = response.data.solicitudes;
          this.temporalAlert({
            show: true,
            message: response.data.message,
            type: "warning",
          });
        } else {
          this.temporalAlert({
            show: true,
            message: "Relación guardada correctamente",
            type: "success",
          });
          this.getRelacionObsOeg();
          this.dialog = false;
          this.relacion = {
            id_relacion: null,
            id_obs: null,
            id_catalogo_cifrado: null,
          };
        }
      }

    },
    editarRelacion(item) {
      this.relacion = {
        id_relacion: item.id,
        id_obs: item.id_obs,
        id_catalogo_cifrado: item.id_catalogo_cifrado,
      };
      this.dialog = true;
    },
    async eliminarRelacion() {

      const response = await this.services.Cifrados.deleteRelacionObsOeg(
        this.relacion.id
      );
      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          message: "Relación eliminada correctamente",
          type: "success",
        });
        this.relacion = {
          id_relacion: null,
          id_obs: null,
          id_catalogo_cifrado: null,
        };
        this.getRelacionObsOeg();
        this.dialogoConfirmacion = false;
      }

    },
    async FetchTipoElementsCifrados() {
      const { data, status } =
        await this.services.Cifrados.getElementsCifrados();

      if (status === 200) {
        this.ctl_tipos_cifrados = data;
      }
    },
  },

  watch: {
    select_tipo_cifrado(value) {
      if (value) {
        this.getOegList(value);
      } else {
        this.oegLs = [];
      }
    },
    "relacion.id_relacion"(value) {
      if (value) {
        this.getOegList(9);
      }
    },
  },

  created() {
    this.getRelacionObsOeg();
    this.getObsList();
    this.FetchTipoElementsCifrados();
  },
};
</script>
