<template>
  <section>
    <h2>Configuración de documento declaración jurada</h2>
    <v-divider class="my-8"></v-divider>


    <!-- tarjeta para subir archivo de declaracion -->
    <v-card>
      <v-card-text>
        <v-row no-gutters class="px-sm-10">
          <v-col cols="12" class="mb-3 text-center text-sm-start">
                        <span class="text-h5 secondary--text">
                          Documento declaración jurada
                        </span>
          </v-col>
          <v-col sm="12" md="8" cols="12" class="my-1">
            <v-file-input
                v-model="infoArchivo.archivo"
                outlined
                prepend-icon=""
                placeholder="Adjuntar archivo"
                label="Archivo *"
                accept="application/pdf"
                prepend-inner-icon="mdi-paperclip"
                @input="$v.infoArchivo.archivo.$touch()"
                @blur="$v.infoArchivo.archivo.$touch()"
                :error-messages="archivoError"
            />
          </v-col>
          <v-col sm="12" md="4" cols="12" class="my-1 text-center justify-center">
            <v-btn
                color="secondary"
                class="white--text mt-4 mt-sm-0"
                @click="download()"
                fab
            >
              <v-icon dark>
                mdi-download
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="mb-3 text-center text-sm-start">
            <v-btn
                color="secondary"
                class="white--text px-8 text-capitalize mt-2 mt-sm-0"
                @click="addDocumento()"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- fin tarjeta -->
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  name: "Configuracion",
  validations: {
    infoArchivo: {
      archivo: {
        required,
        fileNameValid,
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
    },
  },
  data: () => ({
    infoArchivo: {
      archivo: null,
    },
  }),
  computed: {
    archivoError() {
      const errors = [];
      if (!this.$v.infoArchivo.archivo.$dirty) return errors;
      !this.$v.infoArchivo.archivo.required &&
      errors.push("El archivo es requerido");
      !this.$v.infoArchivo.archivo.fileNameValid &&
      errors.push("El archivo debe ser un PDF");
      !this.$v.infoArchivo.archivo.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
  },
  methods: {
    async addDocumento() {
      const formData = new FormData();

      if(!this.infoArchivo.archivo) {
        this.temporalAlert({
          show: true,
          message: "Inserta un documento de declaración jurada",
          type: "error",
        });

        return;
      }

      formData.append("documento", this.infoArchivo.archivo);

      const { status } = await this.services.SolicitudProveedor.postDeclaracionJurada(formData);

      if (status == 201) {
        this.infoArchivo.archivo = null;
        this.temporalAlert({
          show: true,
          message: "Declaracion agregada exitosamente",
          type: "success",
        });
      }
    },
    async download() {
      const { status, data } = await this.services.SolicitudProveedor.getDeclaracionJurada();

      if (status == 200) {
        const fileUrl = window.URL.createObjectURL(new Blob([data], {
          type: "application/pdf",
        }));
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", "Declaración jurada");
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      }
    },
  },
}
</script>
